/// <reference path="/dist/scripts/!shared/angular/angular-bundle.js"
/// <reference path="/dist/scripts/!shared/jquery/jquery-bundle.js"

(function (app) {
    'use strict';
    app.run(['$rootScope', function ($rootScope) {
    }]);

    app.service('helper', [function () {
        return {
        };
    }]);

    app.filter('html', ['$sce', function ($sce) {
        return function (val) {
            return $sce.trustAsHtml(val);
        };
    }]);

    app.service("casesList", ['$location', '$http',
        function ($location, $http) {
            var loadCases = function () {
                return $http.get($location.path() + "?ajax=cases")
                    .then(function (response) {
                        var result = null;
                        if (response && response.data)
                            result = angular.fromJson(response.data);
                        return result;
                    });
            }

            var get = function () {
                return loadCases();
            };

            return {
                get: get
            };
        }]);

    app.controller("Achievements2Ctrl", ['$scope', '$timeout', '$uibModal', '$log', '$http', 'casesList',
        function ($scope, $timeout, $uibModal, $log, $http, casesList) {
            $scope.animationsEnabled = true;
            $scope.cases = [];

            $scope.dotify = function () {
                //console.log("angular dotify");
                // ellipsis title;
                var ellipsis = $(".ellipsis-case-title");
                var nrLines = 2;
                var ellHeight = parseInt(ellipsis.css('line-height'), 10) * nrLines;
                ellipsis.each(function () {
                    $(this).dotdotdot({ height: ellHeight });
                });

                // ellipsis body
                ellipsis = $(".ellipsis-case-body");
                nrLines = 3;
                ellHeight = parseInt(ellipsis.css('line-height'), 10) * nrLines;
                ellipsis.each(function () {
                    $(this).dotdotdot({ height: ellHeight });
                });
            }

            $scope.open = function ($event, link, size) {
                $event.preventDefault();
                if (!link) {
                    var popup = $($event.target).closest("a.popup-link[href]");
                    if (!popup) return;
                    link = popup[0].href;
                }

                $http.get(link).then(function (response) {
                    var modalInstance = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: 'newsModal.html',
                        controller: 'NewsModalInstanceCtrl',
                        size: size,
                        resolve: {
                            item: function () {
                                return response.data.item;
                            }
                        }
                    });
                });
            }
        }]);

    app.controller('NewsModalInstanceCtrl', ['$scope', '$uibModalInstance', 'item',
        function ($scope, $uibModalInstance, item) {
            $scope.item = item;
            $scope.selected = {
                item: item
            };

            $scope.ok = function () {
                $uibModalInstance.close($scope.selected.item);
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);

    app.controller("CarouselNewsItemCtrl", ['$scope',
        function ($scope) {
            $scope.myInterval = 5000;
            $scope.noWrapSlides = false;
        }]);

    app.directive("owlCarousel", [function () {
        return {
            restrict: 'E',
            transclude: false,
            link: function (scope) {
                scope.initCarousel = function (element) {
                    setCarousel("#popupCarousel", 1, true, true);
                };
            }
        };
    }]);

    app.directive('owlCarouselItem', [function () {
        return {
            restrict: 'A',
            transclude: false,
            link: function (scope, element) {
                // wait for the last item in the ng-repeat then call init
                if (scope.$last) {
                    scope.initCarousel(element.parent());
                }
            }
        };
    }]);

    app.filter('trustAsResourceUrl', ['$sce', function ($sce) {
        return function (val) {
            return $sce.trustAsResourceUrl(val);
        };
    }]);

    app.config(['$locationProvider', '$httpProvider', function ($locationProvider, $httpProvider) {
        // to have the correct relative $location.path()
        $locationProvider.html5Mode({ enabled: true, requireBase: false });
        // to have a "correct" AJAX request when $http.get()
        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
    }]);
})(angular.module('casesListApp', ['ngSanitize', 'ngAnimate', 'ui.bootstrap']));

angular.bootstrap(document.getElementById('achievements2'), ['casesListApp']);
angular.bootstrap(document.getElementById('achievements'), ['casesListApp']);
/*
angular.bootstrap(document.getElementById('newsList'), ['newsListApp']);
*/